import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"
import BlogHeader from "../components/headers/blogHeader"
import Footer from "../components/footers/footer"
import Author from "@material-ui/icons/AccountCircle"
import Time from "@material-ui/icons/Schedule"
import "./markdown.css"
export default function Template({ data, pageContext }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <BlogHeader />
      <SEO title={frontmatter.title} description={frontmatter.description} />

      <main style={{ marginTop: "4.4em" }}>
        <div id="post-info-container" className="blog-post-tags">
          <div id="post-tags">
            {frontmatter.tags.map(tag => (
              <li id="post-tag" key={tag}>
                <Link
                  to={`/blog/topic/${tag.replace(/\s+/g, "-").toLowerCase()}`}
                >
                  {tag.replace(/\-+/g, " ")}
                </Link>
              </li>
            ))}
          </div>
        </div>
        <div id="title-container">
          <h1 id="post-title">{frontmatter.title}</h1>
        </div>
        <div id="post-info-container" className="blog-post-info">
          <div id="post-info">
            {" "}
            <Author id="post-info-icon" />
            {frontmatter.author}
          </div>

          <div id="post-info">
            {" "}
            <Time id="post-info-icon" />
            {frontmatter.readTime} min read
          </div>
        </div>
        <div id="post-image-container">
          <Img
            className="post-image"
            fluid={frontmatter.image.childImageSharp.fluid}
            imgStyle={{ objectFit: "cover" }}
            alt="Featured blog image"
          />
        </div>
        <div id="markdown-container">
          <div
            dangerouslySetInnerHTML={{ __html: html }}
            id="markdown-content"
            className="blog"
          />
        </div>
        <section id="start-journey">
          <h2>{frontmatter.footerCTA}</h2>
          <Link
            className="inverted-button"
            target="_blank"
            to="/vicedrop/"
            style={{
              color: "rgb(40, 53, 65)",
              borderColor: "rgb(40, 53, 65)",
            }}
          >
            Start Journey
          </Link>
        </section>
      </main>

      <Footer />
    </>
  )
}
export const pageQuery = graphql`
  query($title: String) {
    markdownRemark(frontmatter: { title: { eq: $title } }) {
      html
      frontmatter {
        title
        description
        footerCTA
        date(formatString: "MMM Do YYYY")
        author
        tags
        type
        readTime
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
